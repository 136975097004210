<template>
  <v-app>
    <UserRolesForm />
    <div class="d-flex">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Filter"
        dense
        clearable
      ></v-text-field>
    </div>
    <v-card class="mb-6">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10000"
        hide-default-footer
        fixed-header
        height="70vh"
        sort-by="name"
        :must-sort="true"
        :search="search"
        :header-props="{
          sortIcon: 'mdi-menu-down',
        }"
        :loading='busy'
        loading-text="Loading users ..."
        no-data-text="No items found."
      >
        <div slot="progress" class="dot-windmill"></div>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <es-btn
              class="es-btn white--text ml-auto no-break"
              color="secondary"
              @click.stop="open(item)"
              :disabled="busy"
              ><fa icon="dice-d6" /> Edit Roles</es-btn
            >
            <es-btn
              :title="`Copy user ID to clipboard`"
              class="es-btn es-btn-copy"
              color="primary"
              @click="copyToClipboard(item)"
              ><fa icon="copy"
            /></es-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { mapState } from "vuex"
import * as clipboardy from "clipboardy"
import UserRolesForm from "@/components/access-management/UserRolesForm.vue"

export default {
  components: {
    UserRolesForm,
  },
  data: () => ({
    search: "",
  }),
  computed: {
    ...mapState("accessManagement", ["busy", "headers", "items"]),
  },
  methods: {
    async copyToClipboard({ user_id }) {
      await clipboardy.write(user_id)
      this.$store.dispatch("snackbar/snack", {
        mode: "success",
        message: `✅ Copied to clipboard: <strong class="px-4">${user_id}</strong>`,
      })
    },
    open(user) {
      this.$store.dispatch( "accessManagement/loadUserRolesForm", user.user_id)
    },
  },
  beforeMount() {
    this.$store.dispatch("accessManagement/fetchRoles")
  },
  mounted() {
    this.$store.dispatch("accessManagement/fetchUsers")
  },
  watch: {
    search(value) {
      if (this.$route.query.search === value) return

      const query = { ... this.$route.query }
      if (!value) delete query.search
      else query.search = value
      this.$router.push({ name: "Access-management", params: this.$route.params, query })
    },
    $route: {
      handler() {
        this.search = this.$route.query.search
      },
      immediate: true
    }
  },
}
</script>
<style lang='scss' scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
}

.no-break {
  white-space: nowrap;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit;
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
}
</style>

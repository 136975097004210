<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card class="pa-6">
      <h1>Roles for {{ user.name }}</h1>
      <v-alert text type="error" v-if="editingSelf">You cannot edit your own roles.</v-alert>
      <v-progress-linear indeterminate v-if="busy" />

      <v-card outlined class="d-flex flex-wrap my-6 pa-3">
        <v-checkbox
          dense
          v-model="selectedRoles"
          v-for="role in roles"
          :key="role.role_id"
          :label="role.label"
          :value="role.role_type"
          :disabled="busy || editingSelf"
        ></v-checkbox>
      </v-card>
      <div class="row">
        <div class="col actions">
          <button
            :disabled="busy || editingSelf"
            class="es-btn teal px-16 ml-auto"
            @click="submit"
          >
            Submit
          </button>
          <span class="es-btn" @click="close">Cancel</span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      selectedRoles: [],
    }
  },
  computed: {
    ...mapState("accessManagement", ["showRolesModal", "items", "user", "roles", "busy"]),
    dialog: {
      get() { return this.showRolesModal },
      set(value) { this.$store.commit("accessManagement/setShowRolesModal", value) },
    },
    editingSelf() {
      const userBeingEdited = this.user.email
      const currentUser = this.$store.getters["account/user"].email
      return userBeingEdited === currentUser
    },
  },
  methods: {
    close() {
      this.dialog = false
    },
    submit() {
      this.$store.dispatch("accessManagement/updateUserRoles", { selectedRoles: this.selectedRoles })
    },
  },
  watch: {
    items(){
      // If there's a uuid parameter in the URL when the items load, open the roles modal.
      if (this.$route.params.uuid) {
        this.$store.dispatch( "accessManagement/loadUserRolesForm", this.$route.params.uuid)
      }
    },
    user({ userRoles }) {
      if (userRoles) this.selectedRoles = userRoles.map(({ role_type }) => role_type)
    },
    dialog(opening) {
      if (opening) {
        if (!this.$route.params.uuid) this.$router.push({ name: "Access-management", params: { uuid: this.user.user_id }, query: this.$route.query })
      }
      else {
        this.selectedRoles = []
        this.$store.commit("accessManagement/setUser", {})
        if (this.$route.params.uuid) this.$router.push({ name: "Access-management", params: {}, query: this.$route.query })
      }
    },
    $route() {
      if (this.$route.params.uuid) {
        this.$store.dispatch("accessManagement/loadUserRolesForm", this.$route.params.uuid)
      }
      else {
        this.dialog = false
      }
    }
  },
}
</script>

<style lang='scss' scoped>
h1 {
  text-align: center;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep {
  .v-label {
    width: 250px;
  }
  .v-messages {
    display: none;
  }
}
</style>
